import ApiService from '@services/api.service'
import { Notify } from 'quasar'

export const etudiantEntretiensSuivi = {
  state: {
    suivis: [],
    suivi: null
  },
  mutations: {
    setSuivis(state, suivis) {
      state.suivis = suivis
    },
    setSuivi(state, suivi) {
      state.suivi = suivi
    },
    callbackDeleteSuivi(state, payload) {
      state.objet = payload.objet
    }
  },
  actions: {
    async removeSuivi(context, uri) {
      try {
        let response = await ApiService.deleteRequest(uri)
        context.commit('callbackDeleteSuivi', response.data)

        Notify.create({
          message: 'Le suivi a été supprimé avec succès',
          color: 'green'
        })
      } catch (error) {
        console.error(error)
        Notify.create({
          message: "Une erreur s'est produite lors de la suppression du suivi",
          color: 'red'
        })
      }
    },

    /**
	 * Modifie la visibilité(is_shared) pour le portail étudiant.
	 * @returns `updateSuivi`
	 */
	async updateSuivi({ commit }, { id, formData }) {
      try {
        const cleanId = id.startsWith('/') ? id.substring(1) : id
        const response = await ApiService.putRequest(cleanId, formData)
        return response.data
      } catch (error) {
        throw error
      }
    }
  },
  getters: {
    getSuivis(state) {
      return state.suivis
    },
    getSuivi(state) {
      return state.suivi
    }
  }
}
