import ApiService from '@services/api.service';
import { Notify } from 'quasar';
import { useStore } from 'vuex';

export const SchedulerEventEmargementEtudiants = {
  state: {
    eventSignatureStudents: [],
    eventSignatureStudent: null,
    objet: null
  },
  mutations: {
    setEventSignatureStudents(state, eventSignatureStudents) {
      state.eventSignatureStudents = eventSignatureStudents;
    },
    setEventSignatureStudent(state, eventSignatureStudent) {
      state.eventSignatureStudent = eventSignatureStudent;
    },
    callbackEditStudentEventSignature(state, payload) {
      state.objet = payload.objet;
    }
  },
  actions: {
    fetchEventsSignaturesBySignature(context, idSignature) {
      try {
        return ApiService.getRequest(`/scheduler_event_emargements/${idSignature}/emargement_etudiants`).then(
          response => {
            context.commit('setEventSignatureStudents', response.data['hydra:member']);
          });
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async fetchEventsEmargementsStudent(context, etudiant){
      return await ApiService.getRequest('/scheduler_event_emargement_etudiants', {etudiant: etudiant}).then(
        response => {
          context.commit('setEventSignatureStudents', response.data['hydra:member'])
        }
      ).catch((error) => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la récupération des données",
          color:'red'
        })
      })
    },
    /* CRUD ÉMARGEMENTS (POST / PATCH) */
      // émargement des stagiaires
    async addStudentEventSignature({ context, dispatch }, data) {
      try {
        const response = await ApiService.postRequest('/scheduler_event_emargement_etudiants', data);
        this.commit('setEventSignatureStudent', response.data);
        if (data && data.sign_off_at !== null && data.status === 0) {
          await dispatch('addEtudiantAbsence', data.absence);
        }

        return response.data;
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        console.error(error);
        return Promise.reject(error);
      }
    },
    async editStudentEventSignature({ context, dispatch }, data) {
      const store = useStore();
      try {
        const response = await ApiService.patchRequest(`/scheduler_event_emargement_etudiants/${data.id}`, data);
        this.commit('callbackEditStudentEventSignature', response.data);
        if (data && data.sign_off_at !== null && data.old_status_parent !== 2) {
          const absence = await dispatch('fetchAbsenceByStudentAndDates', {
            etudiantId: data.absence.etudiant_id,
            dt_start: data.absence_dates.dt_start,
            dt_end: data.absence_dates.dt_end,
            hr_start: data.absence.hr_start,
            hr_end: data.absence.hr_end
          });

          if (data.status === 0 && (absence == null || absence == undefined)) {
            await dispatch('addEtudiantAbsence', data.absence);
          }

          if (data.status === 1 && absence !== undefined && absence !== null){
            const pointage = await dispatch('fetchPointagesByAbsenceId', absence.id);
            if (pointage[0] !== undefined && pointage[0] !== null) {
              let form = new FormData();
              form.append('pointage_id', pointage[0].id);
              form.append('etudiant_id', data.absence.etudiant_id);
              await ApiService.postRequest('/etudiant_pointage_delete_absence', form);
            }
            await ApiService.deleteRequest(`${absence['@id']}`);
          }
        }
        return response.data;
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        console.error(error);
        return Promise.reject(error);
      }
    }
  },
  getters: {
    getEventSignatureStudents(state) {
      return state.eventSignatureStudents;
    },
    getEventSignatureStudent(state) {
      return state.eventSignatureStudent;
    }
  }
}
