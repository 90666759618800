import ApiService from '@services/api.service'
import { Notify } from 'quasar'

export const entretiens = {
  actions: {
    async fetchStagiairesEntretiens({ commit }, { page, filters }) {
      const response = await ApiService.getRequest(
        `stagiaires_entretiens_dashboard?pagination=true&page=${page}`,
        filters
      )
      return response
    },

    async exportEntretiensCSV(_, queryString) {
      return await ApiService.downloadRequest({
        url: `/etudiant_interviews/export.csv?${queryString}`,
        method: 'GET'
      })
    }
  }
}
