import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const groupes = {
  state: {
    groupes: [],
    groupe: null,
  },
  mutations: {
    setGroupes(state, groupes) {
      state.groupes = groupes
    },
    setGroupe(state, groupe){
      state.groupe = groupe
    }
  },
  actions: {
    fetchGroupes(context) {
      return ApiService.getRequest(`groupes?etat=0`).then(
        response => {
          context.commit('setGroupes', response.data['hydra:member'])
        }
      )
        .catch( error => { console.error(error) } )
    },
    //Ajoute un groupe
    async addGroupe(context, formData){
      try{
        const response = await ApiService.postRequest('groupes', formData);
        context.commit('setGroupe', response.data)
        Notify.create({
          message: `${response.data.name} a été ajouté avec succès`,
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de l'ajout du groupe",
          color: 'red'
        })
      }
    },
    //Modifie un groupe
    async updateGroupe(context, data){
      try{
        const response = await ApiService.patchRequest(data.url, data.formData);
        context.commit("setGroupe", response.data);

        Notify.create({
          message: `${response.data.name} a bien été modifié`,
          color: 'green'
        })

      }catch(error){
        console.error(error);
        Notify.create({
          message:"Une erreur s'est produite lors de la modification du groupe.",
          color: 'red'
        })
      }
    },
    //Modifie la liste des stgiaires du groupe
    async updateGroupeEtudiantList(context, data) {
      try{
        const response = await ApiService.patchRequest(data.url, data.stagiaires);
        context.commit('setGroupe', response.data);

        Notify.create({
          message: `${data.groupe} a bien été modifié`,
          color: 'green'
        });
      }catch (error){
        console.error(error);
        Notify.create({
          message: "Un erreur s'est produite lors de la modification du groupe",
          color: 'red'
        })
      }
    },
    //Transfere un étudiant d'un groupe
    async transfereEtudiantGroupe(context, data){
      try{
        const response = await ApiService.postRequest(data.url, data.formData);
        context.commit('setGroupe', response.data);
        Notify.create({
          message: "L'étudiant à été transféré.e avec succès",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la modification du groupe",
          color: 'red'
        })
      }
    },
    // Modifie l'état des groupes et des sessions
    async updateStateGroupeSession(context, data){
      try{
        await ApiService.patchRequest(data.url, {etat: data.etat});
        Notify.create({
          message: "L'élément à bien été modifié",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Un erreur s'est produite",
          color: 'red'
        })
      }
    }
  },
  getters: {
    getGroupes(state) {
      return state.groupes
    },
    getGroupe(state){
      return state.groupe;
    }
  }
}
