import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const antenneSequenceGroup = {
  state: {
    sequenceGroups: [],
    sequenceGroup: null,
    objet: null
  },
  mutations: {
    setSequenceGroups(state, sequenceGroups) {
      state.sequenceGroups = sequenceGroups;
    },
    setSequenceGroup(state, sequenceGroup) {
      state.sequenceGroup = sequenceGroup;
    },
    callbackAddSequenceGroup(state, payload) {
      state.objet = payload.objet;
    },
    callbackEditSequenceGroup(state, payload) {
      state.objet = payload.objet;
    }
  },
  actions: {
    /* CRUD GROUPE SEQUENCE (POST/PATCH/DELETE) */
    async addSequenceGroup({ state }, formData){
      try {
        const response = await ApiService.postRequest('/antenne_sequence_groupes', formData);
        if (response.status === 201) {
          this.commit('callbackAddSequenceGroup', response.data)
          Notify.create({
            message: 'Le groupe séquence a été ajouté avec succès.',
            color: 'green'
          })
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async editSequenceGroup(context, formData){
      try {
        const response = await ApiService.patchRequest(`/antenne_sequence_groupes/${formData.id}`, {
          label: formData.label,
          antenne_referentiels: formData.antenne_referentiels
        });
        if (response.status === 200) {
          this.commit('callbackEditSequenceGroup', response.data)
          Notify.create({
            message: 'Le groupe séquence a été modifié avec succès.',
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    //  * modification de l'état
    async statusSequenceGroup(context, formData){
      try {
        const response = await ApiService.patchRequest(`${formData.id}`, { etat: formData.etat });
        if (response.status === 200) {
          this.commit('callbackEditSequenceGroup', response.data);
          Notify.create({
            message: formData.message,
            color: 'green'
          });
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    /* GROUPE SEQUENCE (GET) */
    fetchSequenceGroups(context, antenneUuid) {
      return ApiService.getRequest(`/sequence_groupes?antenneReferentiels.editable=false&antenne=${antenneUuid}`).then(
        response => {
          context.commit('setSequenceGroups', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        });
    },
    reloadSequenceGroups(context) {
      return ApiService.getRequest('/sequence_groupes?properties[]=id&properties[]=label&properties[]=type_automatic&properties[]=etat&properties[antenne][]=name&properties[antenne_referentiels][]=label&properties[antenne_sequences][]=label&antenneReferentiels.editable=false').then(
        response => {
          context.commit('setSequenceGroups', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        });
    },
    async fetchSequenceGroupById(context, idSequenceGroup) {
      return new Promise((resolve, reject) => {
        ApiService.getRequest(`/antenne_sequence_groupe_detail/${idSequenceGroup}`)
          .then(response => {
            context.commit('setSequenceGroup', response.data);
            resolve();
          })
          .catch(error => {
            console.error(error);
            Notify.create({
              message: "Une erreur s'est produite",
              color: "red"
            });
            reject(error);
          });
      });
    }
  },
  getters: {
    getSequenceGroups(state) {
      return state.sequenceGroups;
    },
    getSequenceGroup(state) {
      return state.sequenceGroup;
    }
  }
}
