import ApiService from '@services/api.service'
import { Notify } from 'quasar'

export const demandeInscription = {
  state: {
    inscription: null,
    inscriptions: [],
    documentsInscription: []
  },
  mutations: {
    setInscription(state, inscription) {
      state.inscription = inscription
    },
    setInscriptions(state, inscriptions) {
      state.inscriptions = inscriptions
    },
    callbackDeleteDemandeInscription(state, demandeInscription) {
      for (let inscription in state.inscriptions) {
        if (state.inscriptions[inscription].id === demandeInscription) {
          state.inscriptions.splice(demandeInscription, 1)
        }
      }
    },
    setDocumentsInscription(state, documentsInscription) {
      state.documentsInscription = documentsInscription
    },
    updateInscriptionStatus(state, { id, status }) {
      if (Array.isArray(state.inscriptions)) {
        state.inscriptions = state.inscriptions.map((inscription) => {
          if (inscription['@id'] === id) {
            return { ...inscription, status }
          }
          return inscription
        })
      } else if (state.inscriptions && state.inscriptions['@id'] === id) {
        state.inscriptions = { ...state.inscriptions, status }
      }
    }
  },
  actions: {
    async fetchDemandeInscriptions(context) {
      return await ApiService.getRequest('external_registration_contacts')
        .then((response) => {
          context.commit('setInscriptions', response.data['hydra:member'])
        })
        .catch((error) => {
          console.error(error)
          Notify.create({
            message:
              "Une erreur s'est produite lors de la récupération des données",
            color: 'red'
          })
        })
    },
    fetchDemandeInscriptionById(context, url) {
      return ApiService.getRequest(url)
        .then((response) => {
          context.commit('setInscription', response.data)
          return response.data
        })
        .catch((error) => {
          console.error(error)
          Notify.create({
            message:
              "Une erreur s'est produite lors de la récupération des données",
            color: 'red'
          })
        })
    },
    async deleteDemandeInscription(context, demandeInscription) {
      try {
        await ApiService.deleteRequest(demandeInscription)
        context.commit('callbackDeleteDemandeInscription', demandeInscription)

        Notify.create({
          message: 'Supprimé avec succès',
          color: 'green'
        })
      } catch (error) {
        console.error(error)
        Notify.create({
          message:
            "Une erreur s'est produite lors de la suppression des données",
          color: 'red'
        })
      }
    },

    async updateDemandeInscriptionStatus(context, { id, status }) {
      try {
        const numericId = id.split('/').pop()

        //N'envoyer que le champ status au lieu de l'objet complet,
        //Cela évite les problèmes de validation avec d'autres champs.
        const updatedData = { status }

        await ApiService.putRequest(
          `external_registration_contacts/${numericId}`,
          updatedData
        )

        context.commit('updateInscriptionStatus', { id, status })

        //Message de notification
        let message = ''
        let color = ''

        if (status === 1) {
          message = 'Demande acceptée et convertie en candidature avec succès'
          color = 'green'
        } else if (status === 2) {
          message = 'Demande refusée avec succès'
          color = 'red'
        } else {
          message = 'Statut de la demande mis à jour avec succès'
          color = 'blue'
        }

        Notify.create({
          message: message,
          color: color
        })

        return true
      } catch (error) {
        console.error('Erreur lors de la mise à jour du statut:', error)
        // // Ajouter plus de détails sur l'erreur pour faciliter le débogage
        // if (error.response) {
        //   console.error('Données de réponse:', error.response.data)
        //   console.error('Statut HTTP:', error.response.status)
        // }
        Notify.create({
          message: "Une erreur s'est produite lors de la mise à jour du statut",
          color: 'red'
        })
        return false
      }
    },

    // Récupere les documents en fonction de la demande d'inscription
    async fetchDocumentInscription(context, idInscription) {
      return await ApiService.getRequest('external_registration_documents', {
        contact: idInscription
      })
        .then((response) => {
          context.commit(
            'setDocumentsInscription',
            response.data['hydra:member']
          )
        })
        .catch((error) => {
          console.error(error)
          Notify.create({
            message:
              "Une erreur s'est produite lors de la récupération des données",
            color: 'red'
          })
        })
    }
  },
  getters: {
    getDemandeInscription(state) {
      return state.inscription
    },
    getDemandeInscriptions(state) {
      return state.inscriptions
    },
    getDocumentsInscription(state) {
      return state.documentsInscription
    }
  }
}
