import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const antenneSequence = {
  state: {
    sequences: [],
    sequence: null,
    objet: null
  },
  mutations: {
    setSequence(state, sequence) {
      state.sequence = sequence;
    },
    setSequences(state, sequences) {
      state.sequences = sequences
    },
    callbackAddSequence(state, payload) {
      state.objet = payload.objet;
    },
    callbackEditStatusSequence(state, payload) {
      state.objet = payload.objet;
    },
    callbackEditSequence(state, payload) {
      state.objet = payload.objet;
    }
  },
  actions: {
    /* SEQUENCE (GET) */
    async fetchSequenceById(context, idSequence) {
      return new Promise((resolve, reject) => {
        ApiService.getRequest(`/antenne_sequence_content/${idSequence}`)
          .then(response => {
            context.commit('setSequence', response.data);
            resolve();
          })
          .catch(error => {
            console.error(error);
            Notify.create({
              message: "Une erreur s'est produite",
              color: "red"
            });
            reject(error);
          });
      });
    },
    async fetchNodesByIdSequence(context, idSequence) {
      const response = await ApiService.getRequest(`/antenne_sequence_contents?antenneSequence=${idSequence}`);

      if (response.status === 200) {
        context.commit('setSequences', response.data['hydra:member']);
        return response.data['hydra:member'];
      } else {
        return Promise.reject(new Error('La requête a échoué.'));
      }
    },
    fetchAllSequences(context) {
      return ApiService.getRequest('/antenne_sequences').then(
        response => {
          context.commit('setSequences', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error)
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        })
    },
    fetchSequences(context, type) {
      return ApiService.getRequest(`/antenne_sequences?type=${type}`).then(
        response => {
          context.commit('setSequences', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error)
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        })
    },
    fetchTests(context) {
      return ApiService.getRequest('/antenne_sequences?type=POSITIONNING').then(
        response => {
          context.commit('setSequences', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error)
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        })
    },
    /* CRUD SEQUENCE (POST/PATCH/DELETE) */
    async addSequence({ state, dispatch }, formData) {
      try {
        const response = await ApiService.postRequest('/add_antenne_sequences', formData);
        if (response.status === 200) {
          if (formData.antenne_sequence_documents.file !== null)
          {
            let form = new FormData();
            form.append('sequence', '/antenne_sequences/' + response.data);
            form.append('antenne', formData.antenne_sequence_documents.antenne)
            form.append('name', formData.antenne_sequence_documents.name);
            form.append('file', formData.antenne_sequence_documents.file);
            form.append('type', formData.antenne_sequence_documents.type);
            await dispatch('addPedagogieDocument', form, { root: true });
          }
          console.log('response', response.data)
          this.commit('callbackAddSequence', response.data)
          Notify.create({
            message: 'La séquence a été ajoutée avec succès.',
            color: 'green'
          })
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async editInformationSequence({ state }, formData) {
      try {

        delete formData.antenne_evaluations;
        delete formData.antenne_pedagogie_documents;
        delete formData.sequences_content;

        const response = await ApiService.patchRequest(`/antenne_sequences/${formData.id}`, formData);
        if (response.status === 200) {
          this.commit('callbackEditSequence', response.data)

          Notify.create({
            message: 'La séquence a été modifiée avec succès.',
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async editSequence({ state, dispatch }, formData) {
      try {

        //  * récupération nodes existants
        const existingNodes = await dispatch('fetchNodesByIdSequence', formData.id);

        //  * suppression nodes existants
        if (existingNodes.length > 0) {
          for (const node of existingNodes) {
            await dispatch('deleteNode', node['@id']);
          }
        }

        //  * initialisation
        let sequenceContent = {};
        let sequenceHaveContent = false;

        sequenceContent = formData.sequences_content;
        if (Object.keys(formData.sequences_content).length > 0) {
          sequenceHaveContent = true;
        }

        delete formData.sequences_content;

        const response = await ApiService.patchRequest(`/antenne_sequences/${formData.id}`, formData);
        if (response.status === 200) {

          //  * edition des noeuds de séquence
          if (sequenceHaveContent) {
            let contentPromises = [];
            for (let key in sequenceContent) {
              if (sequenceContent.hasOwnProperty(key)) {
                // console.log(sequenceContent[key])
                //  * route controleur
                contentPromises.push(await ApiService.postRequest(`/edit_antenne_sequences_content`,  sequenceContent[key]))
                Notify.create({
                  message: "Noeud de séquence créé avec succès.",
                  color: 'light-green-5'
                })
              }
            }
            await Promise.all(contentPromises);
          }

          this.commit('callbackEditSequence', response.data)

          Notify.create({
            message: 'La séquence a été modifiée avec succès.',
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    //  * suppression d'un node par son identifiant
    async deleteNode(context, node) {
      try {
        const response = await ApiService.deleteRequest(`${node}`);
        if (response.status === 204) {
          this.commit('callbackEditStatusSequence', response.data)
          Notify.create({
            message: "Noeud de séquence supprimé avec succès.",
            color: 'light-blue-7'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    //  * modification de l'état
    async statusSequence(context, formData) {
      try {
        const response = await ApiService.patchRequest(`/antenne_sequences/${formData.id}`, { etat: formData.etat });
        if (response.status === 200) {
          this.commit('callbackEditStatusSequence', response.data)
          Notify.create({
            message: formData.message,
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getSequence(state) {
      return state.sequence;
    },
    getSequences(state) {
      return state.sequences;
    }
  }
}
