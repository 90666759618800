const routes = [
  // *** Pages avec accès public ***
  {
    path: '/',
    component: () => import('layouts/Layout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/Login.vue'),
        meta: { publicPages: true }
      }
    ]
  },
  {
    path: '/mot-de-passe-oublie',
    component: () => import('layouts/Layout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/Profil/ForgetPassword.vue'),
        meta: { publicPages: true }
      }
    ]
  },
  {
    path: '/reset-password/reset',
    component: () => import('layouts/Layout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/Profil/ResetPassword.vue'),
        meta: { publicPages: true }
      }
    ]
  },
  {
    path: '/survey/form/:id',
    component: () => import('pages/Survey/Form.vue'),
    meta: { publicPages: true }
  },

  // *** Pages avec accès restreint ***
  {
    path: '/accueil',
    component: () => import('layouts/Layout.vue'),
    children: [{ path: '', component: () => import('pages/Index.vue') }]
  },
  // ***************************
  // *** MODULE CFA/ANTENNE ***
  // ***************************
  // - Liste des antennes
  {
    path: '/futurosud/antennes',
    component: () => import('layouts/Layout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/Futurosud/Antennes.vue'),
        props: (route) => ({
          routeParamDiplomeIri: route.params.routeParamDiplomeIri,
          routeParamDiplomeSessionIri: route.params.routeParamDiplomeSessionIri,
          routeParamAntenneIri: route.params.routeParamAntenneIri
        }),
        name: 'AntennesList'
      }
    ]
  },
  // - Référentiels des diplômes
  {
    path: '/organisme_de_formation/referentiels',
    component: () => import('layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/OrganismeDeFormation/Referentiels.vue')}
    ]
  },
  // - Catalogue des formations (liste des diplômes)
  {
    path: '/futurosud/diplomes',
    component: () => import('layouts/Layout.vue'),
    children: [
      { path: '', component: () => import('pages/Futurosud/Diplomes.vue') }
    ]
  },
  // - Paramétrage du site
  {
    path: '/antenne/parametrage-du-site',
    component: () => import('layouts/Layout.vue'),
    // component: () => import('layouts/ParametrageDuSite.vue'),
    children: [
      // { path: '', component: () => import('pages/Antenne/Parametrage_du_site_OLD.vue') }
      {
        path: '',
        component: () => import('pages/Antenne/Parametrage_du_site.vue'),
        props: (route) => ({
          routeParamDiplomeIri: route.params.routeParamDiplomeIri,
          routeParamDiplomeSessionIri: route.params.routeParamDiplomeSessionIri
        }),
        name: 'ParametrageDuSite'
      }
    ]
  },
  // - Statistiques
  {
    path: '/antenne/statistiques',
    component: () => import('layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/Statistiques')}
    ]
  },
  // - GED Collaborative
  {
    path: '/antenne/ged-collaborative',
    component: () => import('layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/Antenne/GED_collaborative.vue')}
    ]
  },
  // - Gestion des prestataires
  {
    path: '/antenne/gestion-prestataires',
    component: () => import('layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/Futurosud/Gestion_prestataires.vue')}
    ]
  },
  // - Gestion des utilisateurs
  {
    path: '/antenne/gestion-utilisateurs',
    component: () => import('layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/Antenne/Gestion_utilisateurs.vue')}
    ]
  },
  // - Tableau de bord
  {
    path: '/antenne/tableau_de_bord',
    component: () => import('layouts/Layout.vue'),
    children: [
      { path: '', component: () => import('pages/Antenne/Dashboard.vue') }
    ]
  },

  // **************************
  // *** MODULE CANDIDATURE ***
  // **************************
  {
    path: '/candidatures',
    component: () => import('layouts/Candidat.vue'),
    children: [
      { path: '', component: () => import('pages/Candidature') },
      { path: ':id', component: () => import('pages/Candidature/id.vue') }
    ]
  },
  {
    path: '/candidatures/demande-inscriptions',
    component: ()=> import('@layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/Candidature/DemandeInscriptions.vue')}
    ]
  },

  // ************************
  // *** MODULE STAGIAIRE ***
  // ************************
  // - Liste des stagiaires
  {
    path: '/stagiaires',
    component: () => import('layouts/Stagiaire.vue'),
    children: [
      {path: '', component: () => import('pages/Stagiaire')},
      {path: ':id', component: () => import('pages/Stagiaire/id.vue')},
    ]
  },
  // - Gestion des groupes
  {
    path: '/stagiaire/gestion-des-groupes',
    component: () => import('layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/Stagiaire/IndexGroupes.vue')}
    ]
  },
  // - Entretiens
  {
    path: '/stagiaire/entretiens',
    component: () => import('layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/Stagiaire/Entretiens.vue')}
    ]
  },
  // - Emargement
  {
    path: '/stagiaire/emargement',
    component: () => import('layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/Stagiaire/Emargement.vue')}
    ]
  },
  // - Pointage
  {
    path: '/stagiaire/pointage',
    component: () => import('layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/Stagiaire/Pointage.vue')}
    ]
  },
  // - Planning
  {
    path: '/stagiaire/planning',
    component: () => import('layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/Stagiaire/Planning.vue')}
    ]
  },

  // **********************************
  // *** MODULE PEDAGOGIE/FORMATION ***
  // **********************************
  // - France compétences
  {
    path: '/formation/france_competences',
    component: () => import('layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/Formation/FranceCompetences.vue')}
    ]
  },
  // - Référentiels des actions
  {
    path: '/pedagogie/referentiels',
    component: () => import('layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/Pedagogie/Referentiels/Index.vue')},
    ]
  },
  // - Sequences pédagogiques
  {
    path: '/pedagogie/sequences',
    component: () => import('layouts/Sequence.vue'),
    children: [
      {path: '', component: () => import('pages/Pedagogie/Sequences')},
      {path: ':id', component: () => import('pages/Pedagogie/Sequences/id.vue')},
    ]
  },
  // - Tests de positionnement
  {
    path: '/pedagogie/test_positionnement',
    component: () => import('layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/Pedagogie/Tests/Index.vue')}
    ]
  },
  // - Evaluations
  {
    path: '/pedagogie/evaluations',
    component: () => import('layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/Pedagogie/Evaluations/Index.vue')}
    ]
  },
  // - PIF
  {
    path: '/pedagogie/plan_individualise_de_formation',
    component: () => import('layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/Pedagogie/PlanIndividualiseFormation/Index.vue')}
    ]
  },


  {
    path: '/entreprise/evaluation_en_alternance',
    component: () => import('layouts/Layout.vue'),
    children: [
      {path: '', component: () => import('pages/Entreprise/EvaluationEnAlternance.vue')}
    ]
  },



  {
    path: '/statistiques/dashboard',
    component: () => import('layouts/Layout.vue'),
    children: [
      { path: '', component: () => import('pages/Statistiques/Dashboard') }
    ]
  },
  {
    path: '/post-formation/suivi-stagiaire',
    component: () => import('layouts/Layout.vue'),
    children: [{ path: '', component: () => import('pages/SuiviStagiaire') }]
  },
  {
    path: '/post-formation/enquete-satisfaction',
    component: () => import('layouts/Layout.vue'),
    children: [
      { path: '', component: () => import('pages/EnqueteSatisfaction') }
    ]
  },
  {
    path: '/post-formation/questionnaire-insertion',
    component: () => import('layouts/Layout.vue'),
    children: [
      { path: '', component: () => import('pages/QuestionnaireInsertion') }
    ]
  },
  {
    path: '/entreprise',
    component: () => import('layouts/Entreprise.vue'),
    children: [
      {
        path: 'annuaire-entreprise',
        component: () => import('pages/AnnuaireEntreprise')
      },
      {
        path: ':id',
        component: () => import('pages/AnnuaireEntreprise/id.vue')
      }
    ]
  },
  {
    path: '/rgpd',
    component: () => import('layouts/Layout.vue'),
    children: [{ path: '', component: () => import('pages/Rgpd') }]
  },
  {
    path: '/assistance/tickets',
    component: () => import('layouts/Layout.vue'),
    children: [
      { path: '', component: () => import('pages/Assistance/Tickets') }
    ]
  },
  {
    path: '/modifier-profil',
    component: () => import('layouts/Layout.vue'),
    children: [{ path: '', component: () => import('pages/Profil') }]
  },
  {
    path: '/taxes',
    // component: () => import('layouts/Layout.vue'),
    component: () => import('layouts/EntrepriseTaxe.vue'),
    children: [
      // { path: '', component: () => import('pages/Taxes/Annuaire') }
      {
        path: 'annuaire-entreprise',
        component: () => import('pages/Taxes/AnnuaireEntreprise.vue')
      },
      {
        path: 'entreprise/:id',
        component: () => import('pages/Taxes/AnnuaireEntreprise/Fiche.vue'),
        props: true,
        name: 'taxes-entreprise-id'
      }
    ]
  },
  {
    path: '/taxes/statistiques',
    component: () => import('layouts/Layout.vue'),
    children: [
      { path: '', component: () => import('pages/Taxes/Statistiques') }
    ]
  },
  {
    path: '/test',
    component: () => import('layouts/Layout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/Test')
      }
    ]
  },
  {
    path: '/entreprise/apifichemetier',
    component: () => import('layouts/Layout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/Entreprise/ApiFicheMetier.vue')
      }
    ]
  },
  {
    path: '/entreprise/accompagnement-emploi',
    component: () => import('layouts/Layout.vue'),
    children: [
      {
        path: '',
        component: () =>
          import(
            'pages/Entreprise/AccompagnementEmploi/AccompagnementEmploi.vue'
          )
      }
    ]
  },
  {
    path: '/google-forms',
    component: () => import('layouts/Layout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/GoogleApi/GoogleFormsPage.vue')
      }
    ]
  },
  {
    path: '/google-auth-verify',
    component: () => import('layouts/Layout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/GoogleApi/GoogleAuthCallback.vue')
      }
    ]
  }
]

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  })
}

export default routes
